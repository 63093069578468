const QuizConfig = (name, topic) => {
  return {
    init: {
      is_tree: false,
      action_id: "startValidation",
      title: `Hi ${name}, Click below to start your validation process`,
    },
    startValidation: {
      is_tree: false,
      action_id: "validate",
      title: `Would you like to validate this module now?`,
    },
    validate: {
      is_tree: true,
      title: `Validation could make you eligible for future rewards associated with this item.`,
      buttons: [
        {
          action_id: "validateNow",
          label: "Validate now",
        },
        {
          action_id: "complete",
          label: "Do it later",
        },
        {
          action_id: "dontValidate",
          label: "Don't want to validate it",
        },
      ],
    },
    validateNow: {
      is_tree: false,
      isRequired: true,
      title: "Please upload a proof of completionThis question is required.\nDo you have a copy of the certificate?",
      buttons: [
        {
          action_id: "eligbility",
          type: "upload-file",
          label: "Upload file",
        },
      ],
    },
    eligbility: {
      is_tree: true,
      action_id: "shortQuestions",
      title: `Before you are eligible to claim any reward associated with this module`,
    },
    shortQuestions: {
      is_tree: true,
      title: `please continue and answer 3 short questions`,
      buttons: [
        {
          label: "Continue",
          action_id: "aboutRelevance",
        },
      ],
    },
    aboutRelevance: {
      is_tree: false,
      title: `About Relevance`,
      action_id: "Q1",
    },
    Q1: {
      is_tree: true,
      title: `How useful is "${topic}" as a topic`,
      buttons: [
        {
          action_id: "RegardingQuality",
          label: "0",
        },
        {
          action_id: "RegardingQuality",
          label: "1",
        },
        {
          action_id: "RegardingQuality",
          label: "2",
        },
        {
          action_id: "RegardingQuality",
          label: "3",
        },
        {
          action_id: "RegardingQuality",
          label: "4",
        },
        {
          action_id: "RegardingQuality",
          label: "5",
        },
      ],
    },
    RegardingQuality: {
      is_tree: false,
      title: `Regarding Quality`,
      action_id: "Q2",
    },
    Q2: {
      is_tree: true,
      title: `How interesting would you rate the content?`,
      buttons: [
        {
          action_id: "OpportunityToApply",
          label: "0",
        },
        {
          action_id: "OpportunityToApply",
          label: "1",
        },
        {
          action_id: "OpportunityToApply",
          label: "2",
        },
        {
          action_id: "OpportunityToApply",
          label: "3",
        },
        {
          action_id: "OpportunityToApply",
          label: "4",
        },
        {
          action_id: "OpportunityToApply",
          label: "5",
        },
      ],
    },
    OpportunityToApply: {
      is_tree: false,
      title: `Opportunity to apply`,
      action_id: "Q3",
    },
    Q3: {
      is_tree: true,
      title: `Will you get any opportunity to apply the learning?`,
      buttons: [
        {
          action_id: "complete",
          label: "0",
        },
        {
          action_id: "complete",
          label: "1",
        },
        {
          action_id: "complete",
          label: "2",
        },
        {
          action_id: "complete",
          label: "3",
        },
        {
          action_id: "complete",
          label: "4",
        },
        {
          action_id: "complete",
          label: "5",
        },
      ],
    },
    dontValidate: {
      is_tree: true,
      title: "Specific reason to delete:",
      buttons: [
        {
          action_id: "complete",
          label: "Not Intrested",
        },
        {
          action_id: "complete",
          label: "Not applicable",
        },
        {
          action_id: "complete",
          label: "Not relevant",
        },
        {
          action_id: "complete",
          label: "Poor Quality",
        },
        {
          action_id: "complete",
          label: "Other",
        },
      ],
    },
    complete: {
      is_tree: false,
      title: `Thanks, that's all for now. Catch you later.`,
    },
  };
};

module.exports = { QuizConfig };
