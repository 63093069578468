<template>
  <div class="row pt-1">
    <div class="col-12 p-0">
      <div class="d-flex align-items-start px-1">
        <img class="rounded-circle user-avatar" :src="require(`@/assets/images/alex/${alexImage}`)" alt="" srcset="">
        <div class="rounded-lg ml-1 course-container">
          <img class="rounded-lg course-image" :src="feedItem.imageUrl" alt="" srcset="">
          <div class="course-details">{{ feedItem.title }}
            <p class="small p-0">
              {{ feedItem.description }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="isEnabled" class="pt-1 d-flex col-9 justify-content-end">
        <div class="course-start-button" @click='start()'>
          Start
          <img src="../../../assets/images/icons/arrow-right.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isEnabled: true
    }
  },
  methods: {
    start() {
      this.isEnabled = false;
      this.startCallback()
    }
  },
  computed: {
    alexImage() {
      return this.$store.state.user.orgAlexImage;
    },
  },
  props: {
    startCallback: Function,
    feedItem: Object,
  }
};
</script>
<style>
.course-start-button {
  cursor: pointer;
  color: var(--text-primary);
  background-color: var(--button-color);
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.course-start-button>img {
  padding-left: 5px;
}

.course-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
}

.course-details>p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.course-details {
  color: var(--text-secondary);
  font-size: 15px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.course-container {
  width: 60%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--org-color);
}
</style>
