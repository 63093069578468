<template>
  <div class="d-flex align-items-center shadow-lg px-1 header">
    <img class="rounded-circle user-avatar" :src="require(`@/assets/images/alex/${alexImage}`)" alt="" srcset="" />
    <div class="row">
      <div class="font-weight-bold text-wrap col-12">{{ alexName }}</div>
      <div class="d-flex align-items-center small font-weight-light col-12">
        <div class="rounded online-indicatior bg-success"></div>
        Always Active
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    alexImage() {
      return this.$store.state.user.orgAlexImage;
    },
    alexName() {
      return this.$store.state.bot.alexName;
    }
  },
}
</script>