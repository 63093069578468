<template>
  <div class="home overflow-hidden">
    <Loading v-if="isUniversalLoading" />
    <Header />
    <div class="row overflow-auto message-section" ref="scrollableDiv">
      <div class="col-12 p-0 mb-1">
        <Congratulations v-if="quizStatus" />
        <div v-if="!quizStatus">
          <div v-if="validationType == 'quiz'">
            <CourseMessage :startCallback="startQuiz" :feedItem="courseDetails" v-if="!quizStatus" />
            <MultipleQuestion v-if="currentQuizIndex >= 0" />
          </div>
          <div v-if="validationType == 'upload'">
            <UploadValidation />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadValidation from "./upload-quiz/UploadValidation.vue";
import MultipleQuestion from "./multiple-choice-quiz/MultipleChoiceQuiz";
import Congratulations from "./multiple-choice-quiz/Congratulations.vue";
import CourseMessage from "./multiple-choice-quiz/CourseMessage";
import Loading from "@/components/Loading.vue";
import Header from "@/components/bot/Header.vue";
import OrgConfig from "@/assets/data/organizationConfig";
import { QuizConfig } from "./upload-quiz/config";

export default {
  data() {
    return {
      isUniversalLoading: false,
    };
  },
  components: {
    MultipleQuestion,
    UploadValidation,
    CourseMessage,
    Congratulations,
    Loading,
    Header,
  },
  methods: {
    setQuizStatus(data) {
      this.$store.commit("quiz/SET_QUIZ_STATUS", data);
    },
    setQuizIndex(index) {
      this.$store.commit("quiz/SET_CURRENT_QUIZ_INDEX", index);
    },
    setvalidationType(data) {
      this.$store.commit("quiz/SET_QUIZ_TYPE", data);
    },
    pushConversationData(data) {
      this.$store.commit("quiz/PUSH_CONVERSATION_DATA", data);
    },
    setQuizData(data) {
      this.$store.commit("quiz/SET_QUIZ_DATA", data);
    },
    startQuiz() {
      if (this.validationType == 'quiz') {
        this.setQuizIndex(0);
        this.pushConversationData(this.quizData.data[0]);
      } else if (this.validationType == 'upload') {
        this.setQuizIndex(0);
        this.pushConversationData(QuizConfig.init);
      }
    },
    setOrgInfo(orgSlug) {
      var orgDetails = OrgConfig[orgSlug];
      if (!orgDetails) orgDetails = OrgConfig.default;
      this.$store.commit("bot/SET_ALEX_NAME", orgDetails.alexName);
      this.$store.commit("user/SET_ORGANIZATION_ALEX_IMAGE_PATH", orgDetails.alexImagePath);
      let domStyle = document.documentElement.style;
      domStyle.setProperty("--org-color", orgDetails.orgColor);
      domStyle.setProperty("--button-color", orgDetails.buttons);
      domStyle.setProperty("--background", orgDetails.background);
      domStyle.setProperty("--text-primary", orgDetails.textPrimary);
      domStyle.setProperty("--text-secondary", orgDetails.textSecondary);
      domStyle.setProperty("--user-reply-color", orgDetails.userReplyColor);
      domStyle.setProperty("--bot-reply-color", orgDetails.botReplyColor);
    },
    scrollToElement() {
      const scrollableDiv = this.$refs.scrollableDiv;
      if (scrollableDiv) {
        const scrollHeight = scrollableDiv.scrollHeight;
        const clientHeight = scrollableDiv.clientHeight;
        const maxScrollTop = scrollHeight - clientHeight;

        const scrollStep = 5; // step size as needed
        const scrollInterval = 10; // interval for smoother animation

        let currentScrollTop = scrollableDiv.scrollTop;
        const scrollDown = () => {
          if (currentScrollTop < maxScrollTop) {
            currentScrollTop = Math.min(currentScrollTop + scrollStep, maxScrollTop);
            scrollableDiv.scrollTop = currentScrollTop;
            setTimeout(scrollDown, scrollInterval);
          }
        };
        scrollDown();
      }
    },
  },
  computed: {
    alexImage() {
      return this.$store.state.user.orgAlexImage;
    },
    courseDetails() {
      return this.$store.state.quiz.courseDetails;
    },
    validationType() {
      return this.$store.state.quiz.validationType;
    },
    quizStatus() {
      return this.$store.state.quiz.quizCompleted;
    },
    conversationData() {
      return this.$store.state.quiz.conversationData;
    },
    currentQuizIndex() {
      return this.$store.state.quiz.currentQuizIndex;
    },
    quizData() {
      return this.$store.state.quiz.quizData;
    },
    quizId() {
      return this.$store.state.quiz.quizId;
    },
    userId() {
      return this.$store.state.user.userId;
    },
    token() {
      return this.$store.state.user.token;
    },
    organizationId() {
      return this.$store.state.user.organizationId;
    },
  },
  watch: {
    conversationData() {
      this.$nextTick(() => {
        this.scrollToElement();
      });
    },
  },
  created() {
    this.isUniversalLoading = true;
    this.setOrgInfo(this.$route.query.orgSlug);
    this.setvalidationType(this.$route.query.validationType);
    this.$store.commit("quiz/SET_QUIZ_ID", this.$route.query.quizId);
    this.$store.commit("user/SET_USER", this.$route.query.userId);
    this.$store.commit("user/SET_NAME", this.$route.query.name);
    this.$store.commit("user/SET_TOKEN", `Bearer ${this.$route.query.token}`);
    this.$store.commit("user/SET_ORGANIZATION", this.$route.query.organizationId);
    this.$store
      .dispatch("quiz/getQuizStatus")
      .then((res) => {
        if (typeof res === "boolean") {
          this.$store.commit("quiz/SET_QUIZ_STATUS", res);
        }
      })
      .catch((err) => console.log(err));
    this.$store
      .dispatch("quiz/getCompleteQuiz")
      .then((res) => this.setQuizData(res))
      .catch((err) => console.log(err));
    this.$store
      .dispatch("quiz/getFeedItemDetails", { itemId: this.quizId, type: this.$route.query.type })
      .then((res) => {
        this.$store.commit("quiz/SET_COURSE_DETAILS", res);
      })
      .catch((err) => console.log(err))
      .finally(() => this.isUniversalLoading = false);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.post-button>img {
  height: 100%;
  width: 100%;
  border-radius: 50px;
  background-color: transparent;
}

.post-button {
  height: 60%;
  padding-left: 10px;
}

.online-indicatior {
  height: 10px;
  width: 10px;
  margin-right: 4px;
}

.header {
  height: 10vh;
  padding: 5px;
  min-height: 50px;
  color: var(--text-secondary);
  background-color: var(--background) !important;
}

.message-section {
  height: 90vh;
}

.input-section {
  height: 7%;
  min-height: 60px;
}

.user-placeholder {
  width: 2.6rem;
  height: 2.6rem;
}

.user-avatar {
  width: 2.2rem;
  height: 2.2rem;
  object-fit: contain;
  border-radius: 15px;
  border: 2px solid var(--org-color);
  background-color: var(--background);
}

.input-field {
  width: 100%;
  height: 60%;
  border-radius: 15px;
  border: 2px solid var(--org-color);
}

.end-div {
  height: 1px;
  width: 1px;
}

.bot-message {
  color: var(--text-primary) !important;
  background-color: var(--org-color) !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.user-message {
  color: var(--text-secondary) !important;
  background-color: var(--user-reply-color) !important;
  border-top-left-radius: 2rem;
  border-top-right-radius: 0.3rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.home {
  height: 100vh;
  background: var(--background);
}
</style>
