<template>
  <div class="row pt-1">
    <div class="col-9 p-0">
      <div class="d-flex align-items-start px-1">
        <img v-if="showImage" class="rounded-circle user-avatar" :src="require(`@/assets/images/alex/${alexImage}`)" />
        <div v-else class="rounded-circle user-placeholder"></div>
        <div class="flex-grow-0 ml-1 bot-message">
          <p class="p-1 m-0">{{ data.title }}</p>
        </div>
      </div>
      <div v-if="data.choices && isEnabled" class="overflow-auto" style="max-height: 300px">
        <div class="justify-content-end px-1 pt-1" v-for="item in data.choices" :key="item.label">
          <div class="row choice-option">
            <input type="checkbox" :id="item.title" v-on:change="onChange(item, $event)" />
            <div v-if="item.image" class="col-4 p-0 pl-1">
              <img class="" style="height: 100%; width: 100%; object-fit: contain;"
                :src="require(`@/assets/images/activation/${item.image}`)" />
            </div>
            <label :for="item.title" class="col-7">
              {{ item.title }}
            </label>
          </div>
        </div>
      </div>
      <!-- <div v-if="data.buttons && isEnabled" class="overflow-auto d-inline-flex w-100" style="max-height: 300px"> -->
      <div v-if="data.buttons && isEnabled" class="overflow-auto" style="max-height: 300px">
        <div class="d-flex justify-content-end px-1 pt-1" v-for="item in data.buttons" :key="item.label">
          <div class="rounded-circle user-placeholder"></div>
          <div v-if="item.type == 'upload-file'" class="pl-1 justify-content-center">
            <label for="images" class="drop-container p-1 text-center">
              <span class="drop-title">Drop files here</span>
              <br />
              or <br />
              <input type="file" id="images" required @change="imagePicked($event, item)" />
            </label>
          </div>
          <div v-else class="start-button" @click="userResponse(item)">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      isEnabled: true,
      selectedItems: [],
    };
  },
  props: {
    showImage: Boolean,
    callback: Function,
    data: Object,
  },
  methods: {
    imagePicked(event, item) {
      this.selectedItems = event.target.files[0];
      this.userResponse(item);
    },
    userResponse(item) {
      if (this.data.choices && this.data.isRequired && this.selectedItems.length === 0) {
        let timeout = 5000;
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Please select atleast 1 option",
            },
          },
          {
            position: "bottom-right",
            timeout,
          }
        );
        return;
      }
      this.isEnabled = false;
      this.callback({ ...item, selectedItems: this.selectedItems });
    },
    onChange(value, $event) {
      const index = this.selectedItems.findIndex((v) => v == value);
      const checked = $event.target.checked;
      if (checked && index < 0) this.selectedItems.push(value);
      if (!checked && index >= 0) this.selectedItems.splice(index, 1);
    },
  },
  computed: {
    alexImage() {
      return this.$store.state.user.orgAlexImage;
    },
  },
};
</script>

<style>
label {
  color: var(--text-secondary);
}

input[type="file"] {
  width: 100%;
  padding: 5px;
  color: var(--button-color);
  background: var(--background);
  border-radius: 10px;
  border: 1px solid var(--button-color);
}

*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb {
  background: #aaaaaa;
}

.drop-container {
  width: 100%;
  border-radius: 10px;
  border: 2px dashed var(--button-color);
  gap: 10px;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: var(--background);
  border-color: var(--button-color);
}

.drop-container:hover .drop-title {
  color: var(--button-color);
}

.drop-title {
  color: var(--text-secondary);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.choice-option {
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-radius: 0px;
  padding: 10px 15px 10px 15px;
  width: 100%;
  color: var(--text-primary);
  border-color: var(--button-color);
}

.start-button {
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-radius: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--text-secondary);
  border-color: var(--button-color);
}

.start-button>img {
  padding-left: 5px;
}</style>
