let organization = {
  gala: {
    alexName: "Alvox",
    textPrimary: "#fff",
    textSecondary: "#000000",
    buttons: "#3d92fc",
    orgColor: "#3d92fc",
    background: "#fff",
    userReplyColor: "#efefef",
    botReplyColor: "#379CFB",
    alexImagePath: "alvox.png",
  },
  altf: {
    alexName: "Albert",
    textPrimary: "#fff",
    textSecondary: "#000000",
    buttons: "#F36677",
    orgColor: "#F36677",
    background: "#fff",
    userReplyColor: "#efefef",
    botReplyColor: "#379CFB",
    alexImagePath: "ale-bot.png",
  },
  default: {
    alexName: "Alex",
    orgColor: "#f2c046",
    background: "#fff",
    textPrimary: "#fff",
    userReplyColor: "#efefef",
    textSecondary: "#000000",
    buttons: "#f2c046",
    botReplyColor: "#f2c046",
    alexImagePath: "ale-bot.png",
  },
  euruni: {
    alexName: "ALFRED",
    orgColor: "#00BE96",
    background: "#fff",
    textPrimary: "#fff",
    userReplyColor: "#efefef",
    textSecondary: "#000000",
    buttons: "#00BE96",
    botReplyColor: "#00BE96",
    alexImagePath: "ale-bot.png",
  },
  ie: {
    alexName: "ALFRED",
    orgColor: "#0097DC",
    background: "#fff",
    textPrimary: "#fff",
    userReplyColor: "#efefef",
    textSecondary: "#000000",
    buttons: "#0097DC",
    botReplyColor: "#0097DC",
    alexImagePath: "ale-bot.png",
  },
  datarmony: {
    alexName: "ALFRED",
    orgColor: "#30B6BD",
    background: "#fff",
    textPrimary: "#fff",
    userReplyColor: "#efefef",
    textSecondary: "#000000",
    buttons: "#30B6BD",
    botReplyColor: "#30B6BD",
    alexImagePath: "ale-bot.png",
  },
};

export default organization;
