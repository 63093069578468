<template>
  <div>
    <div v-for="item in this.conversationData" :key="item._id || item.userResponse">
      <QuizMessage v-if="item.organizationId" :quizData="item" :callback="submitQuizAnswer" />
      <UserMessage v-if="item.userResponse" :data="item.userResponse.label" />
    </div>
  </div>
</template>

<script>
import QuizMessage from "./QuizMessage";
import UserMessage from "@/components/messages/UserMessage.vue";

export default {
  components: {
    QuizMessage,
    UserMessage,
  },
  methods: {
    setQuizIndex(index) {
      this.$store.commit("quiz/SET_CURRENT_QUIZ_INDEX", index);
    },
    setQuizStatus(data) {
      this.$store.commit("quiz/SET_QUIZ_STATUS", data);
    },
    pushConversationData(data) {
      this.$store.commit("quiz/PUSH_CONVERSATION_DATA", data);
    },
    startQuiz() {
      this.setQuizIndex(0);
      this.pushConversationData(this.quizData.data[0]);
    },
    submitQuizAnswer(data) {
      this.pushConversationData({
        userResponse: data.questionData.choices[data.answer],
        question: data.questionData.question,
      });
      var payload = {
        userId: this.userId,
        feedItemId: this.quizId,
        organizationId: this.organizationId,
        quizQuestionId: data.questionData._id,
        response: data.questionData.choices[data.answer].key,
      };
      this.$store
        .dispatch("quiz/submitResponseToQuiz", payload)
      if (this.currentQuizIndex >= this.quizData.data.length - 1) {
        this.setQuizStatus(true);
        return;
      }
      this.setQuizIndex(this.currentQuizIndex + 1);
      this.pushConversationData(this.quizData.data[this.currentQuizIndex]);
    },
  },
  computed: {
    conversationData() {
      return this.$store.state.quiz.conversationData;
    },
    currentQuizIndex() {
      return this.$store.state.quiz.currentQuizIndex;
    },
    quizData() {
      return this.$store.state.quiz.quizData;
    },
    quizId() {
      return this.$store.state.quiz.quizId;
    },
    userId() {
      return this.$store.state.user.userId;
    },
    organizationId() {
      return this.$store.state.user.organizationId;
    },
  },
};
</script>